import SelectField, { Choice } from 'components/SelectField/SelectField';
import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { getIcon, IconTypes } from 'components/Icon/IconReactIcons';

export interface FagRessursSelector {
  dropdownOptions: any;
  selectedFagRessurser: {
    label: any;
    value: any;
  }[];
  setSelectedFagRessurser: any;
  label: string | undefined;
}

export default function FagRessursSelector({ ...props }: FagRessursSelector) {
  const handleSelectChange = (option: { label: string; value: string }) => {
    if (option.value !== '') {
      props.setSelectedFagRessurser((prevState) => [...prevState, option]);
    }
  };

  return (
    <div className="FagRessursSelector">
      <SelectField
        label={props.label}
        placeholder="fagressurs"
        choices={[...props.dropdownOptions]}
        onChange={handleSelectChange}
        value={null} // overwrite behaviour in input field upon selection
      />

      {props.selectedFagRessurser.map(({ label, value }) => (
        <React.Fragment key={value}>
          <div style={{ marginLeft: 5 }}>{label}</div>
          <Button
            title={getIcon(IconTypes.remove).alt}
            icon
            onClick={() => props.setSelectedFagRessurser((prevState) => prevState.filter((x) => x.value !== value))}
          >
            {getIcon(IconTypes.remove).Component}
          </Button>
        </React.Fragment>
      ))}
    </div>
  );
}
